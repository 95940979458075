/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip {
  visibility: hidden;
  position: absolute;
}
.has-tooltip:hover .tooltip {
  visibility: visible;
  z-index: 100;
}
